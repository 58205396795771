/*-- -------------------------- -->
<---        Core Styles         -->
<--- -------------------------- -*/
/* Mobile */
@media only screen and (min-width: 0em) {
  :root {
    --primary: #ffc400;
    --primary-2: #2c329a;
    --primary-3: #CDDCF2;
    --primaryShade: #DBA907;
    --primaryDark: #b28c0e;
    --bodyTextColor: #575757;
    --backround-1: #F7F7F7;
    --bodyTextColorWhite: #FAFBFC;
    --headerColor: #0f1010;
    --gray-1: #F7F7F7;
    --footer: #1C1C1C;
    --footer-text-color: white;
    /* 13px - 16px */
    --topperFontSize: clamp(1.3rem, 1.6vw, 1.6rem);
    /* 31px - 49px */
    --headerFontSize: clamp(3.1rem, 4.5vw, 4.9rem);
    /* Padding de Vi - 60px - 170px top and bottom  */
    /* --verticalPadding: clamp(6rem, 13.2vw, 17rem); */
    --verticalPadding: clamp(6rem, 13.2vw, 10rem);
    --horizontalPadding: clamp(1.6rem, 3.2vw, 3.2rem);
    --sectionPadding: var(--verticalPadding) var(--horizontalPadding);
    --shadow-md: 0 4px 6px -1px rgba(58, 58, 58, 0.06);
    --shadow-large: 0 10px 19.4px -3px rgba(0, 0, 0, 0.2);
    /*-- -------------------------- -->
    <---         Text styles        -->
    <--- -------------------------- -*/
    /* P 1 */
    --text-p1: clamp(1.6rem, 2vw, 2rem) / 3.2em;
  }
  /* manrope-regular -400 - latin */
  @font-face {
    font-display: swap;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    src: local(''), url('/assets/fonts/Manrope-Regular-400.woff') format('woff'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/assets/fonts/Manrope-Regular-400.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* manrope- medium 500 - latin */
  @font-face {
    font-display: swap;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    src: local(''), url('/assets/fonts/Manrope-Medium-500.woff') format('woff'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/assets/fonts/Manrope-Medium-500.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* manrope- semibold 600 - latin */
  @font-face {
    font-display: swap;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    src: local(''), url('/assets/fonts/Manrope-SemiBold-600.woff') format('woff'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/assets/fonts/Manrope-SemiBold-600.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* manrope- bold 700 - latin */
  @font-face {
    font-display: swap;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    src: local(''), url('/assets/fonts/Manrope-Bold-700.woff') format('woff'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/assets/fonts/Manrope-Bold-700.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  body,
  html {
    margin: 0;
    padding: 0;
    font-size: 62.5%;
    font-family: 'Manrope', Arial, sans-serif;
    color: var(--bodyTextColor);
    overflow-x: hidden;
  }
  *,
  *:before,
  *:after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  body {
    transition: background-color 0.3s;
  }
  .container {
    position: relative;
    width: 92%;
    margin: auto;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    color: var(--headerColor);
  }
  p,
  li,
  a {
    font-size: 1.8rem;
    line-height: 1.5em;
    margin: 0;
  }
  p,
  li {
    color: #575757;
  }
  a:hover,
  button:hover {
    cursor: pointer;
  }
  .dark {
    display: none;
  }
  /*-- -------------------------- -->
<---       Text styles          -->
<--- -------------------------- -*/
  .cs-topper {
    font-size: clamp(1rem, 2vw, 1.4rem);
    color: var(--primary-2);
    letter-spacing: 0.2rem;
    font-weight: 700;
    padding-bottom: clamp(1.6rem, 4.4vw, 2.4rem);
    text-transform: uppercase;
  }
  h1 {
    font-size: clamp(3.4rem, 9.4vw, 4.4rem);
    line-height: 1.2em;
    font-weight: 500;
    padding-bottom: 4rem;
    padding-bottom: clamp(2.4rem, 5.5vw, 3.2rem);
  }
  h2 {
    font-size: clamp(2.4rem, 4vw, 4rem);
    line-height: 1.2em;
    font-weight: 600;
    padding-bottom: clamp(3.2rem, 8.4vw, 4rem);
  }
  h3 {
    font-size: clamp(2.2rem, 6.1vw, 3.2rem);
    line-height: 1.2em;
    font-weight: 500;
  }
  h5 {
    font-size: clamp(1.6rem, 3vw, 1.8rem);
    line-height: 1.2em;
    font-weight: 700;
  }
  .p1 {
    font-size: clamp(1.7rem, 4vw, 2rem);
    font-weight: 400;
    line-height: 1.6em;
  }
  .p2 {
    font-size: clamp(1.6rem, 4.4vw, 1.8rem);
    font-weight: 400;
    line-height: 1.6em;
  }
  .p3 {
    font-size: clamp(1.4rem, 1.5vw, 1.6rem);
    font-weight: 400;
    line-height: 1.6em;
  }
  .p3-caps {
    font-size: clamp(1.4rem, 1.5vw, 1.6rem);
    text-transform: uppercase;
    letter-spacing: 0.14em;
  }
  .p4 {
    font-size: 1.2rem;
  }
  .title-h1 {
    font-size: clamp(4rem, 10.6vw, 6.4rem);
    line-height: 1.2em;
    font-weight: 500;
    padding-bottom: clamp(2.4rem, 3vw, 4rem);
  }
  .title-h1-smaller {
    font-size: clamp(3rem, 8vw, 3.8rem);
    line-height: 0;
  }
  .title-h2 {
    font-size: clamp(3.4rem, 8vw, 5.2rem);
    line-height: 1.2em;
    font-weight: 500;
    padding-bottom: clamp(3.2rem, 8.4vw, 4rem);
  }
  .title-h2-smaller {
    font-size: clamp(2.4rem, 4vw, 3.2rem);
  }
  .title-h3 {
    font-size: clamp(3rem, 8vw, 4rem);
    line-height: 1.2em;
    font-weight: 500;
    padding-bottom: clamp(2.4rem, 6.4vw, 3.2rem);
  }
  .title-h4 {
    font-size: clamp(2rem, 3vw, 2.8rem);
    line-height: 1.2em;
    font-weight: 500;
  }
  .title-h5 {
    font-size: clamp(2rem, 5.3vw, 2.4rem);
    line-height: 1.2em;
    font-weight: 500;
    color: var(--primary-1);
    font-family: 'Barlow Semi Condensed', sans-serif;
    text-transform: uppercase;
  }
  .p1 {
    font-size: clamp(1.7rem, 4vw, 2rem);
    font-weight: 400;
    line-height: 1.8em;
    color: var(--bodyTextColor);
  }
  .p2 {
    font-size: clamp(1.6rem, 4.4vw, 1.8rem);
    font-weight: 400;
    line-height: 1.8em;
    color: var(--bodyTextColor);
  }
  .p3 {
    font-size: clamp(1.4rem, 1.5vw, 1.6rem);
    font-weight: 400;
    line-height: 1.6em;
    color: var(--bodyTextColor);
  }
  .p3-caps {
    font-size: clamp(1.4rem, 1.5vw, 1.6rem);
    text-transform: uppercase;
    letter-spacing: 0.14em;
    color: var(--bodyTextColor);
  }
  .p4 {
    font-size: 1.4rem;
    color: var(--bodyTextColor);
  }
  /*-- -------------------------- -->
<---         Buttons            -->
<--- -------------------------- -*/
  .cs-button-solid {
    font-size: 1.6rem;
    line-height: clamp(2.87em, 5.5vw, 3.5em);
    max-width: 23.8rem;
    text-decoration: none;
    font-weight: 600;
    /* Normal font weight */
    color: var(--headerColor);
    padding: 0.5rem 3.5rem;
    background-color: var(--primary);
    /* Default background color */
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 1.2rem;
    border-radius: 50px;
    position: relative;
    z-index: 1;
    transition: background-color 0.3s, font-weight 0.3s, transform 0.3s;
    /* Added transform to the transition */
  }
  .cs-button-solid:hover {
    background-color: var(--primaryDark);
    /* Change background color on hover */
    font-weight: bold;
    /* Make text bolder on hover */
    transform: scale(1.01);
    /* Slightly increase the size */
  }
  .cs-button-solid:hover:before {
    width: 100%;
    border-radius: 5px;
  }
  .button-secondary {
    background-color: transparent !important;
    border: solid 1px white;
    color: white !important;
  }
  .skip {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -1111111;
  }
  /* READ MORE BUTTON */
  .read-more {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
  }
  .cs-button-link {
    font-size: clamp(1.6rem, 2vw, 1.9rem);
    font-weight: bold;
    text-decoration: underline;
    color: var(--headerColor);
  }
  .button-link-img {
    max-width: 2.6rem;
    height: auto;
  }
  .cs-button-link p {
    display: inline;
  }
  /*-- -------------------------- -->
  <---         SHADOWS            -->
  <--- -------------------------- -*/
  /* Shadow-md */
  .shadow-md {
    box-shadow: 0 4px 6px -1px rgba(58, 58, 58, 0.6);
  }
  /*-- -------------------------- -->
  <---         ANIMATIONS          -->
  <--- -------------------------- -*/
  /* Apply to all elements with AOS animations */
  [data-aos].aos-animate {
    transform: translateZ(0);
  }
}
/* Reset Margins */
@media only screen and (min-width: 1024px) {
  body,
  html {
    padding: 0;
    margin: 0;
  }
}
/* Scale full website with the viewport width */
/* @media only screen and (min-width: 2000px) {
  body,
  html {
    font-size: .85vw;
  }
} */
/*-- -------------------------- -->
<---          PADDINGS           -->
<--- -------------------------- -*/
.margin-bottom-16px {
  margin-bottom: 1.6rem !important;
}
/*-- -------------------------- -->
<---         Navigation         -->
<--- -------------------------- -*/
/* Mobile - Top Drop Down */
@media only screen and (max-width: 1119px) {
  #navigation {
    position: fixed;
    top: -1px;
    left: 0;
    width: 100%;
    z-index: 10000;
    height: auto;
  }
  #navigation .background-color-div {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    width: 100%;
    height: 100%;
    box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
  }
  #navigation .container {
    padding: 1.3rem 0;
    width: 96%;
  }
  #navigation .logo {
    display: inline-block;
    height: 2.5rem;
    width: auto;
  }
  #navigation .logo img {
    width: auto;
    height: 100%;
  }
  #navigation .logo .light {
    display: none;
  }
  #navigation .logo .dark {
    display: block;
  }
  /* BTN HAMBURGER MENU */
  #navigation .hamburger-menu {
    position: absolute;
    right: 0;
    border: none;
    height: 4.8rem;
    width: 4.8rem;
    z-index: 100;
    display: block;
    background: transparent;
    padding: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: top 0.3s;
  }
  #navigation .hamburger-menu span {
    height: 2px;
    width: 3rem;
    background-color: #000;
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    transition: background-color 0.3s;
    color: white;
  }
  #navigation .hamburger-menu span:before {
    content: '';
    position: absolute;
    display: block;
    height: 2px;
    width: 80%;
    background: #000;
    opacity: 1;
    top: -6px;
    left: 0;
    transition: width 0.3s, left 0.3s, top 0.3s, transform 0.5s;
  }
  #navigation .hamburger-menu span:after {
    content: '';
    position: absolute;
    display: block;
    height: 2px;
    width: 80%;
    background: #000;
    opacity: 1;
    top: 6px;
    left: 0;
    transition: width 0.3s, left 0.3s, top 0.3s, transform 0.3s;
  }
  #navigation .hamburger-menu.clicked span {
    background-color: transparent;
  }
  #navigation .hamburger-menu.clicked span:before {
    width: 100%;
    transform: translate(-50%, -50%) rotate(225deg);
    left: 50%;
    top: 50%;
  }
  #navigation .hamburger-menu.clicked span:after {
    width: 100%;
    transform: translate(-50%, -50%) rotate(-225deg);
    left: 50%;
    top: 50%;
  }
  /* CONTENEDOR DEL MENU HAMBURGUESA DESPLEGADO */
  #navigation #navbar-menu {
    position: fixed;
    right: 0;
    padding: 0;
    width: 100%;
    border-radius: 0 0 0.6em 0.6em;
    z-index: -1;
    overflow: hidden;
    box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
    background-color: #fff;
    top: 4.8rem;
    padding-top: 0;
    height: 0;
    transition: height 0.3s, padding-top 0.3s, top 0.3s;
  }
  #navigation #navbar-menu ul {
    padding: 0;
    perspective: 700px;
  }
  #navigation #navbar-menu ul li {
    list-style: none;
    margin-bottom: 3.2rem;
    text-align: center;
    transform-style: preserve-3d;
    opacity: 0;
    transform: translateY(-0.7rem) rotateX(90deg);
    transition: opacity 0.5s, transform 0.5s;
  }
  #navigation #navbar-menu ul li:nth-of-type(1) {
    transition-delay: 0.01s;
  }
  #navigation #navbar-menu ul li:nth-of-type(2) {
    transition-delay: 0.02s;
  }
  #navigation #navbar-menu ul li:nth-of-type(3) {
    transition-delay: 0.03s;
  }
  #navigation #navbar-menu ul li:nth-of-type(4) {
    transition-delay: 0.04s;
  }
  #navigation #navbar-menu ul li:nth-of-type(5) {
    transition-delay: 0.05s;
  }
  #navigation #navbar-menu ul li:nth-of-type(6) {
    transition-delay: 0.06s;
  }
  #navigation #navbar-menu ul li:nth-of-type(7) {
    transition-delay: 0.07s;
  }
  #navigation #navbar-menu ul li a {
    text-decoration: none;
    text-transform: uppercase;
    color: #000;
    font-size: 1.9rem;
    text-align: center;
    display: inline-block;
    position: relative;
    letter-spacing: 1px;
  }
  /* #navigation #navbar-menu ul li a.active:before {
    content: '';
    position: absolute;
    display: block;
    height: 0.8rem;
    background: var(--primary);
    opacity: 1;
    bottom: 8rem;
    border-radius: 0.4rem;
    left: -0.6rem;
    right: -0.6rem;
    z-index: -1;
  } */
  #navigation #navbar-menu.open {
    /* height: 35.6rem; */
    height: auto;
    padding-top: 2rem;
    z-index: -100;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 3.2rem;
  }
  #navigation #navbar-menu.open ul li {
    opacity: 1;
    transform: translateY(0) rotateX(0);
  }
  /* BUTTON */
  #navigation #navbar-menu .cs-button-solid {
    background-color: var(--primary-2);
    padding: 1.25rem 3.1rem;
    line-height: 0;
    color: white;
  }
}
/* Desktop */
@media only screen and (min-width: 1120px) {
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0.6em rgba(0, 0, 0, 0.3);
    background-color: #fff;
  }
  ::-webkit-scrollbar-track-piece {
    background-color: #f0f0f0;
  }
  ::-webkit-scrollbar {
    width: 1.2em;
    background-color: #F5F5F5;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 1em;
    background: var(--primary);
  }
  /* WHEN SCROLLING, WHITE BACKROUND */
  body.scroll #navigation .background-color-div {
    height: 90%;
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
  }
  body.scroll #navigation .logo .light {
    display: none;
  }
  body.scroll #navigation .logo .dark {
    display: block;
  }
  body.scroll #navigation #navbar-menu ul li a {
    color: #1a1a1a;
  }
  body.scroll #navigation #navbar-menu ul li a:hover {
    color: var(--primary-2);
  }
  body.scroll #dark-mode-toggle svg path {
    fill: #000;
  }
  body.dark-mode #navigation .background-color-div {
    height: 100%;
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
  }
  #navigation {
    width: 100%;
    /* height: 10rem; */
    height: 8.5rem;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 200000;
    padding: 0;
    display: flex;
    align-items: center;
  }
  #navigation .background-color-div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    height: 0;
    transition: height 0.3s;
  }
  #navigation .container {
    max-width: 122rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  #navigation .logo {
    /* margin-right: auto; */
    height: 6rem;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #navigation .logo img {
    /* width: auto; */
    width: 12rem;
    height: auto;
  }
  #navigation #navbar-menu {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }
  #navigation #navbar-menu ul {
    padding: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  #navigation #navbar-menu ul li {
    list-style: none;
    margin-left: 3rem;
    padding-top: 0.3rem;
  }
  #navigation #navbar-menu ul li a {
    text-decoration: none;
    /* 14px - 18px */
    font-size: clamp(1.4rem, 1.5vw, 1.6rem);
    position: relative;
    z-index: 1;
    color: #000000;
    transition: color 0.3s;
  }
  /* HOVER */
  #navigation #navbar-menu ul li a:hover {
    color: var(--primary-2);
    /* font-weight: bold; */
  }
  /* LINEA CUANDO HACE HOVER */
  #navigation #navbar-menu ul li a:before {
    content: '';
    position: absolute;
    display: block;
    height: 0.3rem;
    border-radius: 0.3rem;
    background: var(--primary-2);
    opacity: 1;
    bottom: -0.5rem;
    z-index: -1;
    left: 0;
    width: 0%;
    transition: width 0.3s;
  }
  #navigation #navbar-menu ul li a:hover:before {
    width: 100%;
  }
  /* ACTIVE BUTTON */
  #navigation #navbar-menu ul li a.active {
    font-weight: bold;
  }
  #navigation #navbar-menu ul li a.active:before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 0.3rem;
    background: var(--primary-2);
    opacity: 1;
    bottom: -0.5rem;
    border-radius: 0.3rem;
    left: 0rem;
    right: -0.6rem;
    z-index: -1;
    transition: bottom 0.3s;
  }
  #navigation .hamburger-menu {
    display: none;
  }
  /* BUTTON */
  #navigation #navbar-menu .cs-button-solid {
    background-color: var(--primary-2);
    padding: 0.75rem 3.1rem;
    line-height: 0rem;
    color: white;
  }
}
/* Dark Mode Mobile */
/* @media only screen and (max-width: 1023px) {
  body.dark-mode #navigation .background-color-div {
    background-color: var(--dark);
  }
  body.dark-mode #navigation .hamburger-menu.clicked span {
    background-color: var(--dark);
  }
  body.dark-mode #navigation .hamburger-menu span {
    background-color: #fff;
  }
  body.dark-mode #navigation .hamburger-menu span:before,
  body.dark-mode #navigation .hamburger-menu span:after {
    background-color: #fff;
  }
  body.dark-mode #navigation .logo .light {
    display: block;
  }
  body.dark-mode #navigation .logo .dark {
    display: none !important;
  }
  body.dark-mode #navigation #navbar-menu {
    background-color: var(--medium);
  }
  body.dark-mode #navigation #navbar-menu ul li a {
    color: #fff;
  }
  body.dark-mode #navigation #navbar-menu ul li a:before {
    background: var(--accent);
  }
  body.dark-mode #navigation #navbar-menu ul li a.active {
    color: var(--primary);
  }
} */
/* Dark Mode Desktop */
/* @media only screen and (min-width: 1024px) {
  body.dark-mode #navigation .background-color-div {
    background-color: var(--dark);
  }
  body.dark-mode #navigation .logo .light {
    display: block;
  }
  body.dark-mode #navigation .logo .dark {
    display: none !important;
  }
  body.dark-mode #navigation #navbar-menu ul li a {
    color: #fff;
  }
  body.dark-mode #navigation #navbar-menu ul li a:before {
    background: var(--accent);
  }
  body.dark-mode #navigation #navbar-menu ul li a.active {
    color: var(--primary);
  }
} */
/*-- -------------------------- -->
<---    Interior HERO           -->
<--- -------------------------- -*/
/* Mobile - 360px */
@media only screen and (min-width: 0em) {
  #interior-hero {
    padding: var(--sectionPadding);
    padding-bottom: clamp(4rem, 10vw, 10rem);
    padding-top: clamp(10rem, 22vw, 20rem);
    display: flex;
    flex-direction: column;
    background-color: var(--backround-1);
    overflow: hidden;
  }
  #interior-hero .cs-container {
    width: 100%;
    max-width: 55rem;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    z-index: 1;
    gap: 4rem;
  }
  #interior-hero .cs-container .container-text {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    max-width: 71.6rem;
  }
  #interior-hero .cs-container .container-text .p2 {
    font-size: 1.6rem;
  }
  #interior-hero .cs-container .floating-button {
    width: 7rem;
    height: 7rem;
    background-color: var(--primary-2);
    border-radius: 50%;
    display: none;
  }
  #interior-hero .cs-container .floating-button .button-img {
    display: block;
    height: 33px;
    transform: rotate(90deg);
  }
  #interior-hero .cs-container .cs-floater {
    display: none;
    max-height: 60rem;
  }
  #interior-hero .cs-container .container-text .p1 {
    font-size: clamp(1.4rem, 1.5vw, 1.6rem);
    font-weight: 400;
    line-height: 1.6em;
  }
}
/* Tablet - 768px */
@media only screen and (min-width: 768px) {
  #interior-hero .cs-container .floating-button {
    display: none;
  }
  #interior-hero .cs-container .cs-floater {
    width: clamp(15.375rem, 50vw, 28rem);
    position: absolute;
    right: 0rem;
    top: -0.5rem;
    z-index: -1;
    display: block;
  }
}
/* Desktop - 1024px */
@media only screen and (min-width: 1024px) {
  #interior-hero .cs-container {
    max-width: 119rem;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: none;
  }
  #interior-hero .cs-container .floating-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    animation: floating 2s infinite ease-in-out;
    margin-right: clamp(1rem, 2.2vw, 24rem);
    margin-top: -10rem;
  }
}
/* FLOATING BUTTON MOVEMENT */
@keyframes floating {
  0% {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(0, 20px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
/*-- -------------------------- -->
<---    PROJECTS HERO           -->
<--- -------------------------- -*/
/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
  #projects-hero {
    padding-top: clamp(6rem, 16vw, 20rem);
  }
  #projects-hero .cs-container {
    width: 100%;
    max-width: 55rem;
    /* max-width: 119rem;  */
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* row-gap: clamp(3rem,3vw,7.4rem); */
  }
  /* TEXT CONTAINER */
  #projects-hero .cs-left {
    /* max-width: 54.2rem; */
    max-width: 64.8rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: var(--sectionPadding);
  }
  #projects-hero .cs-left .p3-caps {
    padding-bottom: 2.4rem;
  }
  /* PICTURE */
  #projects-hero .cs-right {
    width: 100%;
    max-width: 48.2rem;
    min-height: clamp(40rem, 68vw, 37.5rem);
    position: relative;
    align-self: center;
    border-radius: 10px;
    overflow: hidden;
  }
  #projects-hero .cs-picture img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
/* Desktop - 1024px */
@media only screen and (min-width: 1024px) {
  #projects-hero {
    padding-top: 0rem;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  #projects-hero .cs-container {
    flex-flow: row-reverse;
    justify-content: space-between;
    align-items: stretch;
    gap: 3.25rem;
    width: 100%;
    height: 70vw;
    max-width: 180rem;
    max-height: 100rem;
    margin-right: 0;
    margin-left: 0;
  }
  /* TEXT CONTAINER */
  #projects-hero .cs-left {
    width: 50%;
    margin-left: auto;
    display: flex;
    align-items: left;
  }
  #projects-hero .cs-left p {
    max-width: 43rem;
  }
  /* PICTURE */
  #projects-hero .cs-right {
    max-width: none;
    max-height: none;
    width: 50%;
    height: 100%;
    position: relative;
    padding: 0;
    margin: 0;
    border-radius: 0px;
    overflow: none;
  }
  #projects-hero .cs-picture img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }
}
/* -- ---------------------------------- -->
<---      FINAL CALL TO ACTION           -->
<--- ------------------------------------ -*/
@media only screen and (min-width: 0em) {
  #cta {
    padding: var(--sectionPadding);
    /* padding-bottom: clamp(2rem, 5.5vw, 10.3rem); */
    padding-bottom: clamp(2rem, 5.5vw, 0px);
    background-color: #F7F7F7;
    z-index: 999;
    position: relative;
  }
  #cta .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: clamp(3.2rem, 8.8vw, 4.6rem);
    border-radius: 20px;
    background-color: white;
    background-image: linear-gradient(to right, #95B7FF, #2b329a);
    padding: clamp(3.2rem, 8.8vw, 6rem) 1.6rem clamp(3.2rem, 8.8vw, 6rem) 1.6rem;
    z-index: 1;
  }
  /* black backround */
  #cta::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 32%;
    background-color: var(--footer);
    z-index: -1;
  }
  #cta h2 {
    color: white;
    text-align: center;
    padding-bottom: 0px;
  }
  #cta .color-change {
    color: #FFE400;
  }
  #cta .cs-button-solid {
    max-width: none;
    position: relative;
    /* Ensure it is above the after element */
    z-index: 3;
    /* Ensure it is clickable */
  }
}
/* DESKTOP 1024 */
@media only screen and (min-width: 1024px) {
  #cta .container {
    max-width: 119rem;
  }
  #cta::after {
    height: 40%;
  }
}
/*-- -------------------------- -->
<---         INCLUDES         -->
<--- -------------------------- -*/
/*-- -------------------------- -->
<---                            -->
<---   SBS  Shared Styles Mixin -->
<---                            -->
<--- -------------------------- -*/
/* Desktop Specific Mixin */
/*-- -------------------------- -->
<---     SBS  normal    -->
<--- -------------------------- -*/
/* Mobile - 360px */
@media only screen and (min-width: 0em) {
  #sbs-normal {
    padding: var(--sectionPadding);
    padding-top: clamp(3rem, 10.3vw, 12rem);
    /* PICTURE */
    /* LEFT CONTENT TXT */
  }
  #sbs-normal .cs-container {
    width: 100%;
    max-width: 55rem;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: clamp(5.2rem, 3vw, 7.4rem);
  }
  #sbs-normal .cs-right {
    width: 100%;
    max-width: 48.2rem;
    min-height: clamp(25rem, 68vw, 37.5rem);
    position: relative;
    align-self: center;
    border-radius: 30px;
    overflow: hidden;
    max-height: 30rem;
  }
  #sbs-normal .cs-picture img {
    width: 100%;
    height: auto;
    display: block;
  }
  #sbs-normal .cs-left {
    max-width: clamp(50.7rem, 49.5vw, 60.7rem);
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  #sbs-normal .cs-title {
    width: 100%;
    text-align: left;
    color: var(--headercolor);
    position: relative;
    padding-bottom: clamp(2rem, 6.7vw, 4rem);
  }
  #sbs-normal .txt-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
  }
  #sbs-normal .p2,
  #sbs-normal .p3 {
    padding-bottom: 1.6rem;
  }
  #sbs-normal .padding-bottom-32px {
    padding-bottom: clamp(2.4rem, 6.4vw, 3.2rem);
  }
  #sbs-normal .services-list {
    list-style: none;
    font-weight: 600;
    line-height: 30;
    margin-bottom: 3rem;
  }
  #sbs-normal .services-list li {
    font-size: clamp(1.6rem, 1.2vw, 1.8rem);
    margin-bottom: 1.6rem;
    display: flex;
    align-items: center;
  }
  #sbs-normal .services-list li picture {
    margin-right: 1.5rem;
  }
}
/* Desktop - 1024px */
@media only screen and (min-width: 1024px) {
  #sbs-normal {
    /* PICTURE */
  }
  #sbs-normal .cs-container {
    flex-flow: row;
    justify-content: space-between;
    gap: clamp(3.25rem, 3.1vw, 8rem);
    max-width: 124rem;
  }
  #sbs-normal .cs-right {
    max-width: 54.9rem;
    max-height: 62.7rem;
  }
  #sbs-normal .sbs-picture-max-height {
    max-height: 60rem;
  }
}
/*-- -------------------------- -->
<---          Reviews           -->
<--- -------------------------- -*/
@media only screen and (min-width: 0em) {
  #reviews {
    background-color: #e5ebf5;
    padding: var(--sectionPadding);
  }
  #reviews .reviews-carousel {
    position: relative;
    width: 100%;
  }
  #reviews .reviews-carousel .list {
    display: flex;
    gap: 3.2rem;
    padding: 16px;
    list-style: none;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    /* Hide scrollbar */
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  #reviews .reviews-carousel .list::-webkit-scrollbar {
    display: none;
  }
  #reviews .reviews-carousel .item {
    flex-shrink: 0;
    width: calc(33.33% - 3.2rem);
    /* 3 items visible */
    scroll-snap-align: center;
    min-width: clamp(24.6rem, 65.6vw, 34.6rem);
  }
  #reviews .reviews-carousel .button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background-color: var(--primary-2);
    border: none;
    display: block;
    align-items: center;
    justify-content: center;
    z-index: 2;
    cursor: pointer;
  }
  #reviews .reviews-carousel .button i {
    color: white;
  }
  #reviews .reviews-carousel .button--previous {
    left: -1rem;
    transform: translate(-50%, -50%);
  }
  #reviews .reviews-carousel .button--next {
    right: -1rem;
    transform: translate(50%, -50%);
  }
  #reviews .cs-container {
    width: 100%;
    max-width: 120rem;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: clamp(2.5rem, 7vw, 4rem);
  }
  #reviews .cs-content {
    text-align: center;
    flex-direction: column;
    align-items: flex-start;
  }
  #reviews .title-h2 {
    padding-bottom: 2.2rem;
  }
  #reviews .cs-card-group {
    width: 100%;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: clamp(2.4rem, 6.67vw, 3.2rem);
  }
  #reviews .icon-client-letter {
    border-radius: 100%;
    width: 3.7rem;
    height: 3.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 1.6rem;
  }
  #reviews .color-1 {
    background-color: var(--primary);
  }
  #reviews .color-2 {
    background-color: var(--primary-2);
  }
  #reviews .color-3 {
    background-color: #8E00DB;
  }
  #reviews .cs-item {
    list-style: none;
    width: 100%;
    padding: clamp(1.25rem, 3.15vw, 2.5rem);
    background-color: white;
    box-sizing: border-box;
    grid-column: span 12;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
    box-shadow: var(--shadow-md);
    border-radius: 1.6rem;
    border: #E2E8F0 1rem;
    display: flex;
    gap: 1.8rem;
  }
  #reviews .cs-item .cs-quote {
    font-size: 3rem;
    color: var(--primary-3);
  }
  #reviews .cs-flex-group {
    margin-top: auto;
    display: flex;
    align-items: center;
    gap: 0.8rem;
  }
  #reviews .cs-profile {
    width: 3.25rem;
    height: auto;
    border-radius: 50%;
    position: relative;
    display: block;
  }
  #reviews .cs-name {
    font-size: 1.8rem;
    line-height: 1.2em;
    font-weight: 700;
    color: var(--headerColor);
    display: block;
    text-transform: uppercase;
    font-family: 'Barlow Semi Condensed', sans-serif;
  }
  #reviews .cs-job {
    font-size: 1rem;
    line-height: 1.5em;
    font-weight: 400;
    margin: 0;
    color: var(--bodyTextColor);
    display: block;
  }
  #reviews .wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  #reviews .cs-review {
    font-size: clamp(1.1rem, 3vw, 1.6rem);
    line-height: 1.5em;
    margin: 0;
    color: var(--bodyTextColor);
    z-index: 1;
  }
  #reviews .cs-quote {
    width: 5.375rem;
    height: auto;
    z-index: 0;
  }
  #reviews .review {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--headerColor);
    gap: 0.1rem;
  }
  #reviews .review .google-review {
    display: flex;
    flex-direction: row;
    gap: 2rem;
  }
  #reviews .review .google-review font .p1 {
    font-size: 2.4rem;
  }
  #reviews .review .stars-rating i {
    color: var(--primary);
    padding-bottom: 1rem;
  }
  #reviews .divider {
    width: 0.352rem;
    height: 4.8rem;
    background-color: #E2E8F0;
  }
}
/* Tablet - 768px */
@media only screen and (min-width: 768px) {
  #reviews .cs-item {
    grid-column: span 4;
  }
  #reviews .cs-image {
    height: 3.3rem;
    width: 3.3rem;
  }
  #reviews .review {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  #reviews .review .stars-rating {
    padding-right: 0.8rem;
  }
  #reviews .review .stars-rating i {
    font-size: 2rem;
    padding-bottom: 0px;
  }
}
/*-- -------------------------- -->
<---          STEPS          -->
<--- -------------------------- -*/
/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
  #steps-889 {
    padding: var(--sectionPadding);
  }
  #steps-889 .cs-container {
    width: 100%;
    max-width: 130rem;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* 48px - 64px */
    gap: 4.8rem;
  }
  #steps-889 .cs-content {
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #steps-889 .cs-content .container-span-title {
    display: flex;
    flex-direction: column;
    gap: 0rem;
  }
  #steps-889 .cs-content .container-span-title .cs-topper {
    padding-bottom: 0.8rem;
  }
  #steps-889 .cs-card-group {
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* 20px - 40px */
    gap: clamp(2rem, 4.8vw, 4rem);
  }
  #steps-889 .cs-item {
    text-align: center;
    list-style: none;
    width: 100%;
    max-width: 50rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f2f2f8;
    border-radius: 10%;
    padding: clamp(4rem, 2vw, 6rem) clamp(2rem, 2vw, 3rem);
  }
  #steps-889 .cs-item .title-h4 {
    padding-bottom: 1.4rem;
    font-size: 2rem;
  }
  #steps-889 .cs-item .p3 {
    text-align: left;
  }
  #steps-889 .cs-picture {
    margin-bottom: 2.4rem;
    width: 7.2rem;
    height: 7.2rem;
    background-color: var(--primary-2);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #steps-889 .cs-picture i {
    color: white;
    font-size: 2.5rem;
  }
  #steps-889 .cs-item-p {
    font-size: 1.6rem;
    line-height: 1.5em;
    text-align: inherit;
    margin: 0;
    color: var(--bodyTextColor);
  }
  #steps-889 .cs-arrow {
    /* 48px - 80px */
    width: clamp(4.8rem, 9.6vw, 8rem);
    height: auto;
    display: block;
    flex: none;
    transform: rotate(90deg);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #steps-889 .cs-arrow i {
    font-size: 4rem;
    color: #b6b9e6;
  }
  #steps-889 .cs-arrow-img {
    width: 100%;
    display: block;
  }
  #steps-889 .cs-icon {
    width: 3.2rem;
    height: auto;
    display: block;
  }
}
/* Desktop */
@media only screen and (min-width: 1024px) {
  #steps-889 .cs-container .cs-content {
    flex-direction: row;
    text-align: left;
    gap: 2rem;
  }
  #steps-889 .cs-container .cs-content .container-span-title .title-h4 {
    max-width: 70rem;
    min-width: 40rem;
  }
  #steps-889 .cs-container .cs-content .p3 {
    max-width: 80rem;
    padding-bottom: 3rem;
  }
  #steps-889 .cs-container .cs-card-group {
    gap: clamp(0px, 0vw, 5rem);
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
  }
  #steps-889 .cs-container .cs-card-group .cs-item {
    max-width: 90rem;
  }
  #steps-889 .cs-container .cs-arrow {
    align-self: center;
    transform: rotate(0deg);
  }
  #steps-889 .cs-container .cs-button-solid {
    max-width: none;
    padding: 0.5rem 8.5rem;
  }
}
/*-- -------------------------- -->
<---       Pricing Section       -->
<--- -------------------------- -*/
@media only screen and (min-width: 0rem) {
  #pricing-vn-1 {
    padding: var(--sectionPadding);
  }
  #pricing-vn-1 .cs-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    max-width: 133rem;
    width: 100%;
    gap: clamp(4.8rem, 9.6vw, 6.4rem);
  }
  #pricing-vn-1 .cs-container .cs-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  #pricing-vn-1 .cs-container .cs-content .cs-topper {
    font-size: clamp(1.6rem, 2vw, 2rem);
    font-weight: 700;
    text-transform: uppercase;
    color: var(--primary-2);
  }
  #pricing-vn-1 .cs-container .cs-content .h2-title {
    padding-bottom: clamp(1rem, 8.4vw, 2rem);
  }
  #pricing-vn-1 .cs-container .cs-content .h4-title {
    text-transform: uppercase;
    font-size: clamp(2rem, 6.1vw, 2.4rem);
    letter-spacing: 0.05em;
    color: var(--primary-2);
    padding-bottom: clamp(1rem, 8.4vw, 2rem);
  }
  #pricing-vn-1 .cs-container .pricing-grid {
    display: flex;
    flex-direction: column;
    gap: clamp(1.6rem, 2.8vw, 3.2rem);
    width: 100%;
  }
  #pricing-vn-1 .cs-container .pricing-grid .pricing-card {
    background-color: #f7f7f7;
    border-radius: 1.6rem;
    padding: clamp(2.4rem, 3vw, 4.8rem);
    text-align: left;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }
  #pricing-vn-1 .cs-container .pricing-grid .pricing-card.popular {
    background-color: var(--primary-2);
    color: white;
  }
  #pricing-vn-1 .cs-container .pricing-grid .pricing-card.popular .pricing-title,
  #pricing-vn-1 .cs-container .pricing-grid .pricing-card.popular .pricing-details li,
  #pricing-vn-1 .cs-container .pricing-grid .pricing-card.popular .pricing-price-container .pricing-price,
  #pricing-vn-1 .cs-container .pricing-grid .pricing-card.popular .pricing-price-container .price-comment {
    color: white;
  }
  #pricing-vn-1 .cs-container .pricing-grid .pricing-card.popular i {
    color: white !important;
  }
  #pricing-vn-1 .cs-container .pricing-grid .pricing-card.popular .cs-button-solid {
    color: var(--primary-2);
    background-color: white;
  }
  #pricing-vn-1 .cs-container .pricing-grid .pricing-card.popular .fa-arrow-right {
    color: var(--primary-2) !important;
  }
  #pricing-vn-1 .cs-container .pricing-grid .pricing-card.popular .price-comment {
    color: white !important;
  }
  #pricing-vn-1 .cs-container .pricing-grid .pricing-card.popular .price-and-pill {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }
  #pricing-vn-1 .cs-container .pricing-grid .pricing-card.popular .price-and-pill .price-pill {
    margin-bottom: 1.6rem;
  }
  #pricing-vn-1 .cs-container .pricing-grid .pricing-card .pricing-title {
    font-size: clamp(2.8rem, 3vw, 3.4rem);
    font-weight: 700;
    color: var(--headerColor);
  }
  #pricing-vn-1 .cs-container .pricing-grid .pricing-card .pricing-details {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
  }
  #pricing-vn-1 .cs-container .pricing-grid .pricing-card .pricing-details li {
    font-size: clamp(1.4rem, 2vw, 1.6rem);
    color: var(--bodyTextColor);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row-reverse;
    gap: 0.8rem;
  }
  #pricing-vn-1 .cs-container .pricing-grid .pricing-card .pricing-details li i {
    color: var(--primary-2);
    font-size: 1.6rem;
  }
  #pricing-vn-1 .cs-container .pricing-grid .pricing-card .pricing-price-container .pricing-price {
    font-size: clamp(3.6rem, 3vw, 4.2rem);
    font-weight: 700;
    color: var(--headerColor);
    display: flex;
    gap: 0.8rem;
  }
  #pricing-vn-1 .cs-container .pricing-grid .pricing-card .pricing-price-container .pricing-price .price-comment {
    font-size: clamp(1.4rem, 2vw, 1.4rem);
    font-weight: 600;
    color: var(--bodyTextColor);
  }
  #pricing-vn-1 .cs-container .pricing-grid .pricing-card .pricing-price-container .aclaration {
    font-size: 1.2rem;
  }
  #pricing-vn-1 .cs-container .pricing-grid .pricing-card .pricing-price-container .price-pill {
    display: inline-block;
    background-color: var(--primary);
    color: black;
    font-size: 1.5rem;
    font-weight: 700;
    border-radius: 5rem;
    padding: 0rem clamp(0.5rem, 1vw, 1rem);
    line-height: 0.5em !important;
    display: flex;
    align-items: center;
    max-height: 2.8rem;
    min-height: 3rem;
    align-self: flex-start;
    max-width: 16rem;
  }
  #pricing-vn-1 .cs-container .pricing-grid .pricing-card .pricing-price-container .price-pill .original-price {
    text-decoration: line-through;
    color: red;
    line-height: 0.5em !important;
  }
  #pricing-vn-1 .cs-container .pricing-grid .pricing-card .cs-button-solid {
    padding: 1rem 2.4rem;
    font-size: clamp(1.4rem, 1.8vw, 1.8rem);
    background-color: var(--primary-2);
    color: #fff;
    border-radius: 5rem;
    text-decoration: none;
    transition: background-color 0.3s;
    width: 100%;
    max-width: none;
    line-height: clamp(1.87em, 4.5vw, 2.5em);
  }
  #pricing-vn-1 .cs-container .pricing-grid .pricing-card .cs-button-solid:hover {
    background-color: var(--primaryDark);
  }
  #pricing-vn-1 .cs-container .pricing-grid .pricing-card .pricing-description {
    font-size: clamp(1.4rem, 2vw, 1.8rem);
    color: var(--bodyTextColor);
    margin-bottom: 2.4rem;
  }
}
@media only screen and (min-width: 768px) {
  #pricing-vn-1 .cs-container .pricing-grid {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  #pricing-vn-1 .cs-container .pricing-grid .pricing-card {
    width: 48.5%;
  }
}
@media only screen and (min-width: 1024px) {
  #pricing-vn-1 .cs-container .pricing-grid {
    gap: 3.2rem;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  #pricing-vn-1 .cs-container .pricing-grid .pricing-card {
    padding: 2.8rem;
    width: 32.3%;
  }
  #pricing-vn-1 .cs-container .pricing-grid .pricing-card.popular {
    transform: scale(1.05);
    width: 35%;
  }
  #pricing-vn-1 .cs-container .pricing-grid .pricing-card .pricing-price-container .price-pill {
    margin-bottom: 0px;
  }
}
/*-- -------------------------- -->
<---       Our Projects         -->
<--- -------------------------- -*/
@media only screen and (min-width: 0px) {
  #our-projects {
    padding: var(--sectionPadding);
    background-color: var(--primary-2);
    position: relative;
    /* Added for white overlay */
    overflow: hidden;
    /* Ensures the white background doesn't disrupt layout */
  }
  #our-projects .cs-container {
    width: 100%;
    max-width: 130rem;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: clamp(3rem, 5vw, 6rem);
  }
  #our-projects .cs-container .container-topper-title {
    max-width: 80rem;
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    align-items: center;
  }
  #our-projects .cs-container .container-title-text {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  #our-projects .cs-container .cs-topper {
    color: white;
  }
  #our-projects .cs-container .cs-title {
    font-size: clamp(2.4rem, 3.5vw, 3rem);
    font-weight: 700;
    color: white;
    text-align: center;
    padding-bottom: 0px;
  }
  #our-projects .cs-container .p3 {
    color: white;
    text-align: center;
    max-width: 80rem;
  }
  #our-projects .cs-container .cs-card-group {
    display: flex;
    flex-direction: column;
    gap: clamp(2rem, 4vw, 3rem);
    width: 100%;
    max-width: 111.7rem;
    align-items: center;
    z-index: 4;
  }
  #our-projects .cs-container .cs-card-group .cs-card {
    border-radius: 10px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    transition: transform 0.3s ease;
    max-width: 32rem;
  }
  #our-projects .cs-container .cs-card-group .cs-card picture {
    width: 100%;
    max-width: 30rem;
    margin-bottom: 2rem;
  }
  #our-projects .cs-container .cs-card-group .cs-card picture img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  #our-projects .cs-container .cs-card-group .cs-card picture img:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease-in-out;
  }
  #our-projects .cs-container .cs-card-group .cs-card .cs-card-title {
    font-size: clamp(1.8rem, 2.5vw, 1.8em);
    font-weight: 700;
    color: var(--headerColor);
    margin-bottom: 1rem;
  }
  #our-projects .cs-container .cs-card-group .cs-card .p4 {
    margin-bottom: 1.6rem;
  }
  #our-projects .cs-container .cs-card-group .cs-card .cs-button-solid {
    font-size: 1.4rem;
    padding: 0rem 2.5rem;
    background: var(--primary-2);
    color: #fff;
    border-radius: 50px;
    text-decoration: none;
    transition: background-color 0.3s;
  }
  #our-projects .white-background {
    position: absolute;
    background-color: white;
    top: 32%;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
@media only screen and (min-width: 768px) {
  #our-projects .cs-container {
    max-width: 130rem;
  }
  #our-projects .cs-container .container-title-text {
    flex-direction: row;
    align-items: center;
    gap: 5.8rem;
  }
  #our-projects .cs-container .container-topper-title .cs-topper {
    padding-bottom: 1.6rem;
  }
  #our-projects .cs-container .container-topper-title .cs-title {
    padding-bottom: 2rem;
  }
  #our-projects .cs-container .p3 {
    text-align: left;
    font-size: 1.4rem;
    max-width: 69rem;
  }
  #our-projects .cs-container .cs-card-group {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  #our-projects .cs-container .cs-card-group .cs-card {
    width: 33.3333%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: none;
  }
  #our-projects .white-background {
    top: 53%;
  }
}
/* -------------------------- */
/*      FOOTER          */
/* -------------------------- */
/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
  #vn-footer-1 .cs-top {
    padding: var(--sectionPadding);
    padding-bottom: clamp(8rem, 21.3vw, 10rem);
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    z-index: 10;
    background-color: var(--footer);
  }
  #vn-footer-1 .cs-top .cs-container {
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    column-gap: 2.4rem;
    row-gap: 5.6rem;
  }
  #vn-footer-1 .cs-top .cs-logo-group {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  #vn-footer-1 .cs-top .cs-logo-group .p3,
  #vn-footer-1 .cs-top .cs-logo-group .p3-caps {
    color: white;
  }
  #vn-footer-1 .cs-top .cs-logo-group .p3-caps {
    font-weight: bold;
    max-width: 30rem;
    font-size: 1.4rem;
  }
  #vn-footer-1 .cs-top .cs-logo-group .p3 {
    font-weight: 300 !important;
    font-size: 1.4rem;
  }
  #vn-footer-1 .cs-top .cs-logo-group .cs-button-solid {
    padding: 0rem 3.5rem;
  }
  #vn-footer-1 .cs-top .cs-logo {
    width: 12rem;
    height: auto;
    display: block;
    margin: 0 0 clamp(2rem, 3.2vw, 2.4rem) 0;
  }
  #vn-footer-1 .cs-top .cs-logo-img {
    width: 100%;
    height: auto;
  }
  #vn-footer-1 .cs-top .cs-logo-img.dark {
    display: none;
  }
  #vn-footer-1 .cs-top .cs-text {
    font-size: 1.6rem;
    line-height: 1.5em;
    margin: 0 0 clamp(2rem, 4.8vw, 2.8rem);
    width: 124.8%;
    max-width: 30rem;
    color: var(--footer-text-color);
  }
  #vn-footer-1 .cs-top .cs-text.subtitle {
    font-family: 'Barlow Semi Condensed', sans-serif;
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: normal;
  }
  #vn-footer-1 .cs-top .cs-link {
    font-size: 1.4rem;
    line-height: 1.5em;
    text-decoration: none;
    margin: 0;
    color: var(--footer-text-color);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1.2rem;
    transition: color 0.3s;
  }
  #vn-footer-1 .cs-top .cs-link:hover {
    color: var(--footer-hover-color);
  }
  #vn-footer-1 .cs-top .cs-nav {
    width: 72%;
    max-width: 18rem;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 1.6rem;
  }
  #vn-footer-1 .cs-top .cs-nav-li {
    list-style: none;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  #vn-footer-1 .cs-top .cs-header {
    font-size: 2rem;
    line-height: 1.2em;
    font-weight: 700;
    margin-bottom: 0.8rem;
    text-transform: uppercase;
    color: var(--footer-text-color);
    position: relative;
    display: block;
  }
  #vn-footer-1 .cs-top .header-margin-bottom-16px {
    margin-bottom: 1.6rem;
  }
  #vn-footer-1 .cs-top .cs-nav-link {
    font-size: 1.4rem;
    text-decoration: none;
    line-height: 1.5em;
    width: auto;
    color: var(--footer-text-color);
    position: relative;
    display: inline-block;
    transition: color 0.3s;
  }
  #vn-footer-1 .cs-top .cs-nav-link:before {
    content: "";
    width: 0%;
    height: 0.2rem;
    background: currentColor;
    opacity: 1;
    position: absolute;
    display: block;
    bottom: -0.2rem;
    left: 0;
    transition: width 0.3s;
  }
  #vn-footer-1 .cs-top .cs-nav-link:hover {
    color: var(--footer-hover-color);
    font-weight: 600;
  }
  #vn-footer-1 .cs-top .cs-nav-link:hover:before {
    width: 100%;
  }
  #vn-footer-1 .cs-top .services-list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 1.6rem;
    margin-left: 0px;
    color: var(--footer-text-color);
  }
  #vn-footer-1 .cs-top .services-list .p3 {
    display: flex;
    align-items: center;
    color: var(--footer-text-color);
    font-size: 1.4rem;
    text-decoration: none;
    line-height: 1.5em;
  }
  #vn-footer-1 .cs-top .services-list .p3 a {
    color: var(--footer-text-color);
    list-style: none;
    text-decoration: none;
    font-size: 1.4rem;
  }
  #vn-footer-1 .cs-top .services-list .p3 a:before {
    content: "";
    width: 0%;
    height: 0.2rem;
    background: currentColor;
    opacity: 1;
    position: absolute;
    display: block;
    bottom: -0.2rem;
    left: 0;
    transition: width 0.3s;
  }
  #vn-footer-1 .cs-top .services-list .p3 a:hover {
    color: var(--footer-hover-color);
    font-weight: 600;
  }
  #vn-footer-1 .cs-top .services-list .p3 a:hover:before {
    width: 100%;
  }
  #vn-footer-1 .cs-top .services-list .p3 i {
    margin-right: 1.2rem;
  }
  #vn-footer-1 .cs-top .services-list .p3 picture {
    font-size: clamp(1.6rem, 1.2vw, 1.8rem);
    display: flex;
    align-items: center;
    filter: brightness(0) invert(1);
    margin-right: 1rem;
    margin-top: 0.5rem;
    height: auto;
  }
  #vn-footer-1 .cs-top .services-list .p3 picture img {
    width: 100%;
    height: auto;
  }
  #vn-footer-1 .cs-top .cs-nav.kontakt {
    max-width: 27rem;
  }
  #vn-footer-1 .cs-bottom {
    background-color: #131313;
    margin: auto;
    padding: clamp(2rem, 4.8vw, 3.2rem) 0;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 3.6rem;
    row-gap: 1.2rem;
  }
  #vn-footer-1 .cs-copyright,
  #vn-footer-1 .cs-copyright-link {
    font-size: 1.6rem;
    line-height: 1.5em;
    color: var(--footer-text-color);
    max-width: 128rem;
    margin: auto;
  }
  #vn-footer-1 .cs-copyright {
    text-align: center;
    width: 100%;
  }
  #vn-footer-1 .cs-copyright-link {
    text-decoration: none;
    transition: color 0.3s;
  }
  #vn-footer-1 .cs-copyright-link b {
    color: var(--primary);
  }
  #vn-footer-1 .cs-copyright-link b:before {
    content: "";
    width: 0%;
    height: 0.2rem;
    background: currentColor;
    opacity: 1;
    position: absolute;
    display: block;
    bottom: -0.2rem;
    left: 0;
    transition: width 0.3s;
  }
  #vn-footer-1 .cs-copyright-link b:hover {
    color: var(--footer-hover-color);
    text-decoration: underline;
  }
  #vn-footer-1 .cs-copyright-link b:hover:before {
    width: 100%;
  }
  #vn-footer-1 .cs-copyright-link:hover {
    color: var(--footer-hover-color);
  }
}
/* Tablet - 768px */
@media only screen and (min-width: 768px) {
  #vn-footer-1 .cs-container {
    row-gap: 0;
    flex-direction: row;
    flex-wrap: nowrap;
    column-gap: 8.8rem;
    row-gap: 4rem;
  }
  #vn-footer-1 .cs-nav {
    width: auto;
  }
  #vn-footer-1 .cs-bottom {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  #vn-footer-1 .cs-copyright {
    text-align: left;
    width: auto;
    margin-right: auto;
  }
  #vn-footer-1 .cs-floater {
    display: block;
  }
}
/* Small Desktop - 1024px */
@media only screen and (min-width: 1077px) {
  #vn-footer-1 .cs-container {
    max-width: 128rem;
    flex-wrap: nowrap;
    justify-content: flex-end;
    column-gap: clamp(4rem, 9.6vw, 8.4rem);
  }
  #vn-footer-1 .cs-logo-group {
    width: 48%;
    max-width: 35.7rem;
    margin-right: auto;
    align-items: flex-start !important;
  }
  #vn-footer-1 .cs-text {
    width: 100%;
  }
  #vn-footer-1 .cs-graphic {
    display: block;
  }
}
/* -------------------------- */
/*      COOKIE BANNER         */
/* -------------------------- */
@media only screen and (min-width: 0rem) {
  #cookie-banner {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #2c2c2c;
    color: #fff;
    z-index: 9999;
    font-family: Arial, sans-serif;
    border-top: 1px solid white;
    box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.3);
    /* Top shadow */
    display: none;
    justify-content: center;
  }
  #cookie-banner .cookie-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1rem;
    flex-wrap: wrap;
  }
  #cookie-banner .cookie-content .cookie-text-container p {
    line-height: 1.1;
    color: white;
  }
  #cookie-banner .cookie-content .cookie-text-container p a {
    text-decoration: underline;
    font-weight: bold;
    color: #fff;
  }
  #cookie-banner .cookie-content .cookie-text-container p a:hover {
    color: #ccc;
  }
  #cookie-banner .cookie-content .cookie-text-container .p3 {
    font-size: 1rem;
    padding-bottom: 0.8rem;
  }
  #cookie-banner .cookie-content .cookie-text-container .p2 {
    padding-bottom: 0.4rem;
    font-weight: bold;
    font-size: 1.2rem;
  }
  #cookie-banner .cookie-content .cookie-buttons {
    display: flex;
    gap: 1rem;
    flex-direction: row-reverse;
    width: 100%;
  }
  #cookie-banner .cookie-content .cookie-buttons .cs-button-solid {
    width: clamp(25rem, 22vw, 35rem);
    max-width: none;
    line-height: clamp(2.6em, 5.5vw, 2em);
    font-size: 1.4rem;
  }
  #cookie-banner .cookie-content .cookie-buttons .button-secondary {
    background-color: transparent;
    color: white;
    max-width: 14rem;
    text-decoration: underline;
    font-size: 1.2rem;
    border: 0px;
  }
  #cookie-banner .cookie-content .cookie-buttons .button-secondary:hover {
    background-color: transparent !important;
    color: white !important;
    text-decoration: underline !important;
    border: 0px !important;
    font-weight: bold;
  }
}
/* Tablet - 768px */
@media only screen and (min-width: 768px) {
  #cookie-banner .cookie-content {
    flex-wrap: nowrap;
    gap: 2rem;
    max-width: 130rem;
  }
}
/* Small Desktop - 1024px */
@media only screen and (min-width: 1024px) {
  #cookie-banner .cookie-content {
    justify-content: space-between;
  }
  #cookie-banner .cookie-content .cookie-buttons {
    flex-direction: row;
    width: auto;
  }
  #cookie-banner .cookie-content .cookie-text-container .p3 {
    font-size: 1.2rem;
  }
  #cookie-banner .cookie-content .cookie-text-container .p4 {
    font-size: 1.4rem;
  }
}
/*# sourceMappingURL=root.css.map */